

































































































































import { Component, Vue } from 'vue-property-decorator'
import { LoyoutModule } from '@/store/modules/layout'
import { Toast } from 'vant'
import { addGroupInvitation, getGroupInfo } from '@/api'
import { getPlatform } from '@/utils'

@Component({
  name: 'Invitegroup'
})
export default class Invitegroup extends Vue {
  pla = getPlatform()
  // 被邀请人名称
  invitedName = ''
  // 被邀请人手机号
  invitedMobile = ''

  // 是否显示引导
  isGuidance = false

  // 是否注册allo
  isReg = 0

  // 是否显示表单
  isForm = true

  defaultUrl = require('@/assets/default_head.png')

  info: Info = {
    faceUrl: '',
    inviterName: '',
    groupName: '',
    memberCount: 0,
    invitationConfirmType: 0,
    memberList: []
  }

  created() {
    // 获取群信息
    this.getGroupInfo()

    // 获取当前语言
    const data = this.$route.query.currentLang as string

    this.$i18n.locale = data || 'zh'

    LoyoutModule.setTitle(this.$t('邀请加群'))

    if (this.pla === 'weixin' || this.pla === 'qq') {
      return false
    } else {
      this.isForm = false
      this.isReg = localStorage.getItem('isReg') as any
    }
  }

  get language() {
    return this.$i18n.locale
  }

  // 获取群信息
  async getGroupInfo() {
    const { data } = await getGroupInfo({
      groupId: this.$route.query.groupId || 11328,
      inviterUserId: this.$route.query.inviterUserId || 2249,
      inviterName: this.$route.query.inviterName,
      groupName: this.$route.query.groupName
    })
    this.info = { ...data }
    console.log(this.info)
  }

  // 点击下载
  handelDown() {
    this.isGuidance = true
    if (this.pla === 'weixin' || this.pla === 'qq') {
      this.isGuidance = true
    } else {
      // 如果用户已经安装App直接跳转App
      window.location.href = 'allo://tel.top/home/callshow?select=1'
      // 如果用户打开App失败，直接跳转下载
      setTimeout(function() {
        window.location.href =
          'http://source.allosoft.top/apk/1.9.5/allo_v1.9.5_release_195_31_dianshi_sign.apk'
      }, 500)
      this.isGuidance = false
    }
  }

  // 点击打开
  handelOpen() {
    this.isGuidance = true
    if (this.pla === 'weixin' || this.pla === 'qq') {
      this.isGuidance = true
    } else {
      // 如果用户已经安装App直接跳转App
      window.location.href = 'allo://tel.top/home/callshow?select=1'
      // 如果用户打开App失败，直接跳转下载
      setTimeout(function() {
        window.location.href = process.env.VUE_APP_ALLO
      }, 500)
      this.isGuidance = false
      this.isForm = false
      this.isReg = localStorage.getItem('isReg') as any
    }
  }

  // 加入群聊事件
  async handelJoin() {
    const tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    // 判断是否含有emoji表情
    const emoji = /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g
    if (this.info.invitationConfirmType) {
      Toast(this.$t('该群已开启进群验证，只可通过群主邀请进群'))
    } else if (this.invitedMobile == '') {
      Toast(this.$t('请输入您的电话号码'))
    } else if (!tel.test(this.invitedMobile)) {
      Toast(this.$t('您输入的号码格式不正确'))
    } else if (emoji.test(this.invitedName)) {
      Toast(this.$t('名字中不能输入表情'))
    } else {
      const { data, code } = await addGroupInvitation({
        groupId: this.$route.query.groupId,
        inviterUserId: this.$route.query.inviterUserId,
        invitedName: this.invitedName,
        invitedMobile: this.invitedMobile,
        language: this.$route.query.currentLang
      })
      if (code == 1001) {
        Toast(this.$t('加入成功'))
        this.getGroupInfo()
        this.isReg = data
        this.isForm = false
        localStorage.setItem('isReg', data)
      }
    }
  }
}
