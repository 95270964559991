




































































































































import { Component, Vue } from 'vue-property-decorator'
import { getPlatform } from '@/utils/verify'
import { getUserInfoByUserId } from '@/api'
@Component({
  name: 'Information'
})
export default class Information extends Vue {
  // 是否显示引导
  isGuidance = false
  //   免打扰
  checked = false
  //  屏蔽
  isShield = true
  //   个性签名
  selfSignature = ''
  //   昵称
  name = ''
  // 手机号
  mobile = ''
  //   头像
  headUrl = ''
  isShow = true
  isPage = false

  created() {
    // 获取用户信息
    this.getUserInfoByUser()

    // 获取当前语言
  }

  get language() {
    return this.$i18n.locale
  }

  // 获取用户信息
  async getUserInfoByUser() {
    const { data } = await getUserInfoByUserId({
      userId: this.$route.query.userId
    })
    const { name, mobile, headUrl, selfSignature } = data
    this.name = name
    this.mobile = mobile
    this.headUrl = headUrl
    this.selfSignature = selfSignature
  }

  // 立即下载
  handelDownload() {
    const pla = getPlatform()
    if (pla === 'weixin' || pla === 'qq') {
      this.isGuidance = true
    } else {
      this.isPage = true
      this.isGuidance = false
      this.isShow = false
      // 如果用户已经安装App直接跳转App
      window.location.href = `allo://chat.info/chat/userInfo?userId=${this.$route.query.userId}`
      // 如果用户打开App失败，直接跳转下载
      setTimeout(function() {
        window.location.href = process.env.VUE_APP_ALLO
      }, 500)
    }
  }
}
